import logo from '../assets/img/logos/vistara.svg';
import logoWhite from '../assets/img/logos/vistara.svg';
import rollingBanner1Web from '../assets/img/banners/vistara/IndiaGeneric.jpg';
import rollingBanner1Mob from '../assets/img/banners/vistara/IndiaGeneric.jpg';
import rollingBanner1Tab from '../assets/img/banners/vistara/IndiaGeneric.jpg';
import localization from '../localization';
var banner = require('../assets/img/banners/Campsited2.jpg');
var favicon = require('../assets/img/Eticket.svg');

export default {
  name: 'Club Vistara - Tours and Activities',
  clientName: 'Club Vistara - Tours and Activities',
  config: {
    showMapsByDefault: false,
    googleAnalyticsKey: '',
    usePrimaryColoredBanner: true,
    customLogoHeight: '100px',
    contactUsLink: 'http://tripadmit.com/enquire',
    supportLink: 'http://tripadmit.com/enquire',
    aboutUsLink: 'http://tripadmit.com/',
    cookiePolicyLink: 'http://tripadmit.com/cookie-policy',
    privacyPolicyLink: '/policy/terms',
    tosLink: '/policy/terms',
  },
  meta: {
    title: 'Club Vistara - Tours and Activities',
    description: 'Connecting Experiences',
    home: '/home',
  },
  colors: {
    primary: '#582c4f',
    secondary: '#b99650',
    inactive: '#BCBCBC',
    searchBanner: '#582c4f',
  },
  fonts: {
    colors: {
      primary: '#582c4f',
      secondary: '#b99650',
      inactive: '#BCBCBC',
      searchBanner: '#582c4f',
    },
    primary: 'MontserratRegular',
    regular: 'MontserratRegular',
    light: 'MontserratRegular',
    bold: 'MontserratBold',
  },
  styles: {},
  bannerImages: {
    images: [
      {
        web: rollingBanner1Web,
        mob: rollingBanner1Mob,
        tab: rollingBanner1Tab,
        text: localization.rollingBanner.static,
        link: '',
      },
    ],
  },
  assets: {
    logo,
    favicon,
    banner,
    logoWhite,
  },
  currency: {
    default: 'INR',
    defaultWithSymbol: 'INR ₹',
    available: {
      INR: 'INR ₹',
    },
  },
  language: {
    default: 'en',
    available: ['en'],
  },
};
