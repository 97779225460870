import React from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import { Title, Paragraph, Paragraphs, Block } from '../../../components/Common/Policies';

import localization from '../../../localization';

class TermsAndConditions extends React.Component {
  render() {
    return (
      <div className="TermsAndConditions" style={{}}>
        <Header />
        <div style={{ textAlign: 'left', width: '80vw', margin: 'auto', marginTop: '5vh' }}>
          <Title>{localization.policies.terms.title} - Vistara</Title>
          <ul>
            <li>
              Vistara reserves the right to modify, discontinue or terminate this arrangement, at
              any point without prior notice and shall not be liable to any CV member or any person
              for any such modification, discontinuation or termination. Vistara will in no event be
              liable for any direct, indirect, special, incidental, or consequential loss/damages,
              bodily injury or death arising out of this Offer or purchase, use or non-use of any
              products/services under this Offer.
            </li>
            <li>
              Vistara does not make any warranties or representations about the quality,
              merchantability, suitability or availability of services offered by Trip Admit. You
              acknowledge that Vistara does not endorse, control or guarantee the quality or fitness
              of any such products/services. Any dispute in relation to delivery, suitability,
              merchantability, availability, fitness or quality of the products/services availed
              under the Offer must be referred by the customer directly to Trip Admit.
            </li>
            <li>
              Vistara is not an agent or an intermediary of Trip Admit. Trip Admit reserves the
              right to contract with you and Vistara does not control Trip Admit and cannot compel
              them to contract with you. Vistara will not be a party to any contract that you enter
              into with Trip Admit.
            </li>
            <li>
              These terms and conditions are in addition to and not in derogation to the terms and
              conditions as applicable to CV members specified on our website www.airvistara.com,
              whose terms and conditions shall also be applicable to this Offer.
            </li>
            <li>
              This Offer shall be governed by the laws of India. Any dispute arising out of or in
              connection with this Offer shall be subject to the exclusive jurisdiction of the
              courts of Delhi.
            </li>
            <li>
              By availing this Offer, the customers shall be deemed to have accepted all the
              aforesaid terms and conditions.
            </li>
            <li>
              For any booking-related queries, please write to TripAdmit - info@tripadmit.com or
              support@tripadmit.com
            </li>
            <li>
              Offer is subject to change without notice. In case of any dispute, Club Vistara and
              TripAdmit reserve the right to the final decision.
            </li>
          </ul>
          <Title>{localization.policies.terms.title} - TripAdmit</Title>
          <Paragraph>{localization.policies.terms.intro}</Paragraph>
          <Paragraph>{localization.policies.terms.breakdown.title}</Paragraph>

          <Paragraphs content={localization.policies.terms.breakdown.content} />

          <Title>{localization.policies.terms.generalTerms.title}</Title>
          {localization.policies.terms.generalTerms.content.map((content, key) => (
            <Block key={key} content={content} />
          ))}

          <Title>{localization.policies.terms.intermediary.title}</Title>
          <Paragraph>{localization.policies.terms.intermediary.intro}</Paragraph>
          {localization.policies.terms.intermediary.content.map((content, key) => (
            <Block key={key} content={content} />
          ))}

          <Title>{localization.policies.terms.userTerms.title}</Title>
          <Block content={localization.policies.terms.userTerms.introduction} />
          <Block content={localization.policies.terms.userTerms.serviceOfTripAdmit} />
          <Block content={localization.policies.terms.userTerms.payments} />
          <Block content={localization.policies.terms.userTerms.availability} />
          <Block content={localization.policies.terms.userTerms.liability} />
          <Block content={localization.policies.terms.userTerms.dataProtection} />
          <Block content={localization.policies.terms.userTerms.changesToTerms} />
          <Block content={localization.policies.terms.userTerms.otherProvisions} />
          <hr />
        </div>
        <Footer />
      </div>
    );
  }
}

export default TermsAndConditions;
