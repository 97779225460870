import React, { useState } from 'react';
import styled from 'styled-components';
import localization from '../../../localization';
import { TickedCheckboxIcon } from '../../Common';
import { BookingOptionVertical } from './BookingOption';
import { FontSizes } from '@constants';
import UrgencyCountdown from '../../Common/Countdown';

import tourRecap from '../../../assets/img/poweredByStripe/TourRecap_TrustSignals.svg';

const Data = styled.div`
  padding: 0 22px;
  padding-bottom: 22px;
  width: 100%;
`;
const WhyTripadmitElement = styled.div`
  margin-bottom: 5px;
  font-size: ${FontSizes.cta};
  font-weight: 600;
  font-family: ${(props) => props.theme.fonts.primary};
`;
const SecondTitle = styled.div`
  font-size: 17px;
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: 10px;
`;
const VerticalFormDivider = styled.hr`
  margin: 15px 0;
  background-color: #d7d7d7;
  border-color: #d7d7d7;
`;

const WhyWe = ({ list }) => (
  <>
    {list.map((text, i) => (
      <WhyTripadmitElement key={i}>
        <TickedCheckboxIcon style={{ width: '17px', marginRight: '10px' }} /> {text}
      </WhyTripadmitElement>
    ))}
  </>
);
const Sidebar = ({ bookings }) => {
  const [timer, setTimer] = useState();

  return (
    <div className="booking-summary-block">
      {bookings.map((booking, i) => (
        <BookingOptionVertical booking={booking} key={i} />
      ))}
      <Data>
        <UrgencyCountdown timer={timer} setTimer={setTimer} bookingHash={bookings[0].bookingHash} />
        <VerticalFormDivider />
        <SecondTitle>{localization.payment.whyTripadimit.title}</SecondTitle>
        <WhyWe list={localization.payment.whyTripadimit.content} />
        <br />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <img src={tourRecap} width="100%" alt="Tour Recap Trust Signals" />
        </div>
      </Data>
    </div>
  );
};

export default Sidebar;
