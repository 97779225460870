import { Card, CardActionArea, CardMedia } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontSizes } from '../../utils/constants';
import './CardBase.scss';
import localTheme from 'theme';

const CardText = styled.div`
  color: white;
  position: absolute;
  margin: auto;
  line-height: 15px;
  font-size: 15px;
  left: 8px;
  bottom: 14px;
  display: flex;
  margin: 5px;
  text-overflow: ellipsis;
  font-weight: bold;
  justify-content: left;
  align-items: left;
  font-family: 'MontserratBold';
  text-align: left;
`;

const CardTextMob = styled.div`
  color: white;
  position: absolute;
  margin: auto;
  line-height: 10.8px;
  font-size: 10.8px;
  left: 0;
  bottom: 0;
  display: flex;
  margin: 5px;
  text-overflow: ellipsis;
  font-weight: bold;
  justify-content: left;
  align-items: left;
  font-family: 'MontserratBold';
  text-align: left;
`;

const MobileBase = styled.div`
  width: 96px;
  height: 124px;
`;

const DesktopBase = styled.div`
  width: 148px;
  height: 174px;
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: 'unset',
    width: 'fit-content',
  },
  rootDesktop: {
    borderRadius: '7px',
    textAlign: 'left',
    backgroundColor: 'unset',
    width: 'fit-content',
  },
  media: (props) => ({
    width: '100%',
    position: 'relative',
    height: '174px',
    borderRadius: '5px',
    filter: 'brightness(80%)',
    backgroundSize: props.isMouseOver ? '240%' : '200%',
    backgroundColor: localTheme.colors.primary,
  }),
  mediaMob: {
    width: '100%',
    backgroundSize: 'cover',
    position: 'relative',
    height: '124px',
    borderRadius: '5px',
    filter: 'brightness(80%)',
    backgroundColor: localTheme.colors.primary,
  },
  title: {
    textAlign: 'left',
    lineHeight: '13px',
    fontSize: FontSizes.cta,
    fontWeight: 'bold',
  },
  tag: {
    display: 'inline-block',
    fontSize: '11px',
    borderRadius: '0.5rem',
    padding: '2px 0.5rem',
    color: '#fff',
    marginBottom: '0.5rem',
    marginRight: '4px',
    height: '19px',
    lineHeight: '16px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 184,
  },
  cardBody: {
    flex: '1 0 auto',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
const PlaceCardBase = ({ mobile, title, link, imageUrl, isClickable }) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const classes = useStyles({ isMouseOver });

  const openInSameTab = (url) => {
    window.open(url, '_self');
  };

  if (mobile) {
    return (
      <Card
        className={classes.root}
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
      >
        <MobileBase style={{ borderRadius: '5px' }}>
          <CardActionArea
            onClick={() => {
              if (!isClickable) {
                openInSameTab(link);
              }
            }}
          >
            <CardMedia component="img" className={classes.mediaMob} image={imageUrl}></CardMedia>
            <CardTextMob>{title}</CardTextMob>
          </CardActionArea>
        </MobileBase>
      </Card>
    );
  } else {
    return (
      <Card
        className={classes.rootDesktop}
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
      >
        <DesktopBase style={{ borderRadius: '5px' }}>
          <CardActionArea
            onClick={() => {
              if (!isClickable) {
                openInSameTab(link);
              }
            }}
          >
            <CardMedia component="img" className={classes.media} image={imageUrl}></CardMedia>
            <CardText>{title}</CardText>
          </CardActionArea>
        </DesktopBase>
      </Card>
    );
  }
};

export default PlaceCardBase;
