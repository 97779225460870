import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendConfirmationEmail } from '../../../api/emails';
import { bookholdbook } from '../../../api/viator';

import cvcLogo from '../../../assets/img/payment/CVC.svg';
import discoverLogo from '../../../assets/img/payment/discover.svg';
import jcbLogo from '../../../assets/img/payment/jcb.svg';
import maestroLogo from '../../../assets/img/payment/maestro.svg';
import mastercardLogo from '../../../assets/img/payment/mastercard.svg';
import visaLogo from '../../../assets/img/payment/visa.svg';
import localization from '../../../localization';
import '../customCardStyles.css';
import {
  getAllBookingQuestionAnswers,
  getStripeResult,
  getTotalPaymentAndCurrency,
  mapBookingForHoldAndBook,
  mapConfirmationEmailData,
} from './paymentModuleHelper';

const logo = {
  mastercard: mastercardLogo,
  visa: visaLogo,
  maestro: maestroLogo,
  jcb: jcbLogo,
  discover: discoverLogo,
};
const stripe = loadStripe(
  'pk_live_51NaIi6EhT6AzydfnvdpGuFgUMUYggRcY1vMSSkF6IswAlSeWduWekfuVoGbGNBXEZPFtwW0QjRXc4LbJnlYi5jtW00E0EF6Tma'
);
//const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);
/*
const stripe = loadStripe(
  'pk_live_51Lgpv3AIyfk65SUY6NOxQBpA9aellvUk1Jc4pTJ257agIAT2KxqD5jWnAYh5gysON6FIem5smMSUpWOsZXGL5Xi200D2KUZNge'
);*/
export function ViatorPaymentModule(args) {
  return (
    <Elements stripe={stripe}>
      <PaymentModuleInner {...args} />
    </Elements>
  );
}

const BookingConfirmationStatus = {
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
};

const RejectionReasonInfo = {
  BOOKABLE_ITEM_IS_NO_LONGER_AVAILABLE:
    localization.payment.tourOptions.bookingHold.rejectionReason.noLongerAvailable,
  WEATHER: localization.payment.tourOptions.bookingHold.rejectionReason.weather,
  DUPLICATE_BOOKING: localization.payment.tourOptions.bookingHold.rejectionReason.duplicate,
  PRODUCT_NO_LONGER_OPERATING:
    localization.payment.tourOptions.bookingHold.rejectionReason.noLongerOperating,
  MINIMUM_NUMBER_OF_PASSENGERS_NOT_MET:
    localization.payment.tourOptions.bookingHold.rejectionReason.minNumberOfPassengersNotMet,
  SIGNIFICANT_GLOBAL_EVENT_FORCE_MAJEURE:
    localization.payment.tourOptions.bookingHold.rejectionReason.forceMajeure,
  TOUR_WAS_CANCELLED: localization.payment.tourOptions.bookingHold.rejectionReason.cancelled,
  ISSUE_WITH_TICKET: localization.payment.tourOptions.bookingHold.rejectionReason.ticketIssue,
  ISSUE_WITH_PICKUP: localization.payment.tourOptions.bookingHold.rejectionReason.pickupIssue,
  OTHER: localization.payment.tourOptions.bookingHold.rejectionReason.other,
};

function CustomCardElement(onChange, options, brandLogo) {
  return (
    <div className="credit-card">
      <div className="credit-card-number">
        <span className="credit-card-number-brands">
          {brandLogo.length ? <img className="brand-logo" src={brandLogo} alt="BrandLogo" /> : null}
        </span>
        <CardNumberElement options={options} onChange={onChange} />
      </div>
      <div className="credit-card-date">
        <CardExpiryElement options={options} onChange={onChange} />
      </div>
      <div className="credit-card-cvc">
        <img className="cvc-icon" src={cvcLogo} alt="Cvc icon" />
        <CardCvcElement options={options} onChange={onChange} />
      </div>
    </div>
  );
}

function PaymentModuleInner({ FormContainer, showModal, setBookError, stripeRefund, tour }) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [brandLogo, setBrandLogo] = useState('');
  const [windowWidth, setWindowWidth] = useState(0);
  const isMobileView = windowWidth < 450;

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setWindowWidth]);

  const options = {
    style: {
      base: {
        color: '#5a5a5a',
        fontWeight: 500,
        border: 'none',
        fontSize: '16px',
      },
    },
  };

  const handleChange = (event) => {
    setIsPaymentDetailsFilled(event.complete);

    if (event.brand) {
      setBrandLogo(logo[event.brand] || '');
    }
  };

  const [isPaymentDetailsFilled, setIsPaymentDetailsFilled] = React.useState(false);

  const [checkoutInProgress, setCheckoutInProgress] = React.useState(false);

  const onSubmit = async (paymentDetails) => {
    console.log('submitting payment details', paymentDetails);
    try {
      if (!isPaymentDetailsFilled) {
        showModal('Please, fill in payment details');
        return;
      }
      if (!stripe || !elements) {
        console.error('no stripe');
        return;
      }
      setCheckoutInProgress(true);

      const result = await getStripeResult(stripe, elements, CardNumberElement, paymentDetails);

      if (result.error) {
        showModal(
          `We encountered an error processing payment, please try again. Reason: ${result.error.code}, ${result.error.message}`
        );
      } else {
        //TODO: Need to validate booking should be correct before payment is taken
        const bookingQuestionAnswers = await getAllBookingQuestionAnswers(paymentDetails);
        const book = mapBookingForHoldAndBook(paymentDetails, bookingQuestionAnswers);
        book.paymentIntentId = result.paymentIntent.id;
        const { total } = getTotalPaymentAndCurrency(paymentDetails);

        // pass total in case we'll get bookingQuestions error and do refund
        const confirmedBooking = await bookholdbook({
          ...book,
          total,
          vistaraNum: paymentDetails.cardholder.vistaraNum,
        });

        if (confirmedBooking.data.data.error) {
          setBookError(RejectionReasonInfo.OTHER);
          return;
        }

        const { status, rejectionReasonCode, orderDetails } = confirmedBooking.data.data;

        if (status === BookingConfirmationStatus.REJECTED) {
          setBookError(RejectionReasonInfo[rejectionReasonCode]);
          stripeRefund(orderDetails.paymentIntentId, total);

          return;
        }

        if (status === BookingConfirmationStatus.CONFIRMED) {
          await sendConfirmationEmail(
            mapConfirmationEmailData(paymentDetails, confirmedBooking.data.data)
          );

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: paymentDetails.booking.bookingRef,
              value: total / 100,
              currency: paymentDetails.booking.bookerCurrency || paymentDetails.booking.currency,
              items: [
                {
                  item_id: paymentDetails.booking.tourId,
                  item_name: paymentDetails.booking.tour._default_title,
                  currency:
                    paymentDetails.booking.bookerCurrency || paymentDetails.booking.currency,
                  price: total / 100,
                  travelDate: paymentDetails.booking.date,
                  paxMix: paymentDetails.booking.bookingHold.paxMix,
                },
              ],
            },
          });

          history.push(
            `/vorder-confirmation/${paymentDetails.booking.bookingRef}/${confirmedBooking.data.data.partnerBookingRef}`
          );
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setCheckoutInProgress(false);
    }
  };

  return (
    <FormContainer
      isCheckoutInProgress={checkoutInProgress}
      paymentForm={CustomCardElement(handleChange, options, brandLogo)}
      onSubmit={onSubmit}
    />
  );
}
