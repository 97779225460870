import { StyleBreakpoints } from '@constants';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import { pick } from 'lodash/fp';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import PoweredBy from '../../components/PoweredBy';
import localization from '../../localization';
import { FontSizes } from '../../utils/constants';
import { formatIfInr, formatPrice, getPriceWithCurrencySymbol } from '../../utils/helpers';
import { Bold, ButtonLargeWithHover } from '../Common';
import Sidebar from '../Common/ViatorBookingSummarySidebar/Sidebar';
import { LoaderVerySmall } from '../Loader';
import { StyledField } from './FormCommon/Input';
import CardholderForm from './FormCommon/CardholderForm';
import CustomQuestions from './FormCommon/CustomQuestions';
import PurchaseTotal from './FormCommon/PurchaseTotal';
import TravelerForm from './FormCommon/TravelerForm';
import { ScrollToError } from './ScrollToError';

import './style.scss';

yup.addMethod(yup.number, 'validClubNumberDigits', function (errorMessage) {
  return this.test(`test-club-num-digits`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      value.toString().length === 9 || createError({ path, message: '9 digits were expected' })
    );
  });
});

yup.addMethod(yup.number, 'mod7Check', function (errorMessage) {
  return this.test(`test-valid-club-num`, errorMessage, function (value) {
    const { path, createError } = this;
    const valueAsString = value.toString();

    //var mod7Remainder = parseInt(value) % 7
    //console.log('mod7Remainder =', mod7Remainder)
    var First8Digits = parseInt(value.toString().substring(0, 8));

    var divBy7 = First8Digits / 7;
    var mod7Remainder = 0;
    if (divBy7.toString().indexOf('.') > -1) {
      mod7Remainder = parseInt(divBy7.toString().split('.')[1][0]) * 0.1;
    }
    var mod7By7 = mod7Remainder * 7;
    var mod7CheckDigit = mod7By7 == 0 ? mod7By7 : Math.ceil(mod7By7);
    var inboundWithCheck = parseInt(First8Digits.toString() + mod7CheckDigit.toString());

    return inboundWithCheck == value || createError({ path, message: 'Invalid Club Vistara ID' });
  });
});

const PurchaseButton = styled(ButtonLargeWithHover)`
  min-width: 240px;
  max-width: unset;
  width: auto;
  height: 56px;
  margin: 18px 0 30px;
  font-size: 18px;
  border-radius: 6.7px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.5);
  background-color: #0f1928;
  height: 44px;
  @media screen and (max-width: ${StyleBreakpoints.md}px) {
    width: 100%;
  }
`;
const Section = styled.div`
  margin-bottom: 30px;
`;
const Title = styled(Bold)`
  font-size: ${FontSizes.h2};
  margin: 20px 0;
  display: block;
`;

const useStyles = makeStyles(() => ({
  billingDetails: {
    padding: '24px!important',
  },
  rightBlock: {
    padding: '24px!important',
  },
}));

const initialState = {
  cardholder: {
    firstName: '',
    lastName: '',
    email: '',
    emailConfirmation: '',
    phoneNumber: '',
  },
  traveler: {
    firstName: '',
    lastName: '',
    email: '',
    emailConfirmation: '',
    phoneNumber: '',
  },
  promo: '',
};

async function initialFormState(booking) {
  return {
    ...initialState,
    bookingParameters: booking.bookingParameters?.map((x) => ({ ...x, value_1: '' })),
  };
}

function validationSchema() {
  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

  const cardholder = yup.object({
    firstName: yup.string().required().min(3).max(40).label(localization.payment.form.firstName),
    lastName: yup.string().required().min(3).max(60).label(localization.payment.form.lastName),
    email: yup.string().email().required().label(localization.payment.form.email),
    emailConfirmation: yup
      .string()
      .email()
      .required()
      .test('email-match', 'Emails must match', function (value) {
        return this.parent.email === value;
      })
      .label(localization.payment.form.confirmEmail),
    phoneNumber: yup
      .string()
      .required()
      .matches(phoneRegExp, 'Phone number is not valid')
      .label(localization.payment.form.mobile),

    vistaraNum: yup
      .number()
      .min(2)
      .validClubNumberDigits('Incorrect number of digits')
      .mod7Check('Invalid Club Number')
      .label(localization.payment.form.vistaraNum),
  });

  return yup.object({ cardholder });
}

export default function PaymentForm({
  booking,
  paymentForm,
  onSubmit,
  isCheckoutInProgress,
  paymentFailed,
  bookError,
}) {
  const [isTravelerDetailsSame, setTravelerDetailsSame] = useState(true);
  if (!booking.bookingParameters) {
    booking.bookingParameters = [];
  }
  const [displayPromo, setDisplayPromo] = useState(false);

  const [transferArrivalMode, setTransferArrivalMode] = useState(null);
  const [transferDepartureMode, setTransferDepartureMode] = useState(null);
  const formikRef = React.useRef(null);
  const classes = useStyles();

  const validateClubId = async (e, setFieldError) => {
    const expectedField = 'cardholder.vistaraNum';

    if (e.target.name === expectedField && e.target.value.length >= 9) {
      await validationSchema(isTravelerDetailsSame)
        .validateAt(expectedField, { cardholder: { vistaraNum: e.target.value } })
        .then(() => setFieldError(expectedField, null))
        .catch((err) => setFieldError(expectedField, err.message));
    }
  };

  return (
    <Formik
      initialValues={initialFormState(booking)}
      validateOnChange={false}
      enableReinitialize={true}
      validateOnBlur={false}
      innerRef={formikRef}
      onSubmit={({ traveler, cardholder, bookingParameters, customQuestionsAnswers, promo }) => {
        onSubmit({
          cardholder,
          promo,
          bookingParameters,
          customQuestionsAnswers,
          language: localization.getLanguage(),
          traveler: isTravelerDetailsSame
            ? pick(
                ['firstName', 'lastName', 'email', 'emailConfirmation', 'phoneNumber'],
                cardholder
              )
            : traveler,
          booking,
        });
      }}
      validationSchema={validationSchema()}
    >
      {({ setFieldValue, setFieldError }) => (
        <Form onChange={async (e) => await validateClubId(e, setFieldError)}>
          <ScrollToError />
          <Grid container spacing={6} style={{ width: '100%', margin: '0' }}>
            <Grid item xs={12} md={7} className={`${classes.billingDetails} left-block`}>
              <h1 className="main-heading">{localization.payment.form.billingDetails}</h1>
              <CardholderForm displayPromo={displayPromo} setDisplayPromo={setDisplayPromo} />

              <StyledField placeholder={'Club Vistara ID'} name="cardholder.vistaraNum" />

              <TravelerForm
                travelerDetailsSame={isTravelerDetailsSame}
                setTravelerDetailsSame={setTravelerDetailsSame}
              />
              {booking.tour.bookingQuestions?.length > 0 && (
                <Section>
                  <Title>{localization.payment.form.infoRequested}</Title>
                  <CustomQuestions
                    questions={booking.tour.bookingQuestions}
                    booking={booking}
                    formikRef={formikRef}
                    transferArrivalMode={transferArrivalMode}
                    transferDepartureMode={transferDepartureMode}
                    tour={booking.tour}
                    setFieldValue={setFieldValue}
                    setArrivalMode={function (name) {
                      return function (e) {
                        setFieldValue(name, e.target.value);
                        setTransferArrivalMode(e.target.value);
                      };
                    }}
                    setDepartureMode={function (name) {
                      return function (e) {
                        setFieldValue(name, e.target.value);
                        setTransferDepartureMode(e.target.value);
                      };
                    }}
                  />
                </Section>
              )}

              {bookError ? (
                <div style={{ color: 'red' }}>
                  {`${localization.payment.tourOptions.bookingHold.error.partOne} `}
                  <b>{bookError}</b>
                  {`. ${localization.payment.tourOptions.bookingHold.error.partTwo}`}
                </div>
              ) : (
                <>
                  <Section>
                    <Title>{localization.payment.form.creditCardDetails}</Title>
                    {paymentForm}
                  </Section>

                  {paymentFailed ? (
                    <div style={{ color: 'red' }}>
                      Processing your payment failed, please check your card details and try again
                    </div>
                  ) : null}
                  <PurchaseTotal
                    total={booking.priceDetails.totalInUserCurrency || booking.priceDetails.total}
                    isCheckoutInProgress={isCheckoutInProgress}
                    bookerCurrency={booking.bookerCurrency}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={12} md={5} className={classes.rightBlock}>
              <Sidebar
                bookings={[booking]}
                totalPrice={booking.priceDetails.totalInUserCurrency || booking.priceDetails.total}
              />
              {!bookError && (
                <PurchaseButton
                  className="second-submit-button"
                  type="submit"
                  disabled={isCheckoutInProgress}
                >
                  {isCheckoutInProgress ? (
                    <LoaderVerySmall />
                  ) : (
                    <>
                      <span>{localization.payment.completePurchase} </span>
                      {getPriceWithCurrencySymbol(
                        formatIfInr(
                          formatPrice(
                            booking.priceDetails.totalInUserCurrency || booking.priceDetails.total
                          )
                        ),
                        booking.bookerCurrency
                      )}
                    </>
                  )}
                </PurchaseButton>
              )}
            </Grid>
          </Grid>

          <div style={{ textAlign: 'center' }}>
            <PoweredBy style={{ width: '48px', margin: 'auto' }} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
