import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { getBooking } from '../../api/viator';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import PaymentForm from '../../components/ViatorTourPaymentForm';
import { ViatorPaymentModule } from '../../components/ViatorTourPaymentForm/Payment/ViatorPaymentModule';
import { FETCH_CART } from '../../store/checkout/actionTypes';
import { REFUND_STRIPE_PAYMENT } from '../../store/stripe/actionTypes';

function ViatorPayment({ match, showModal, stripeRefund }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [booking, setBooking] = useState(null);
  const [tour, setTour] = useState(null);
  const [bookError, setBookError] = useState(null);

  // eslint-disable-next-line react/display-name
  const WrappedPaymentForm = (booking) => (paymentModuleArgs) =>
    <PaymentForm booking={booking} {...paymentModuleArgs} tour={tour} bookError={bookError} />;

  const bookingFetcher = getBooking;
  useEffect(() => {
    setIsLoaded(false);
    bookingFetcher(match.params.cartId).then(async (booking) => {
      setBooking(booking.data);
      //setCurrencyNoRerender(booking.data.currency.toLowerCase());
      setIsLoaded(true);
    });
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      transaction_id: booking.bookingRef,
      value: booking.priceDetails.totalInUserCurrency || booking.priceDetails.total,
      currency: booking.bookerCurrency || booking.currency,
      items: [
        {
          item_id: booking.tourId,
          item_name: booking.tour._default_title,
          currency: booking.bookerCurrency || booking.currency,
          price: booking.priceDetails.totalInUserCurrency || booking.priceDetails.total,
          travelDate: booking.date,
          paxMix: booking.bookingHold.paxMix,
        },
      ],
    },
  });

  return (
    <div className="Tour">
      <Header />
      <div
        style={{
          maxWidth: '1220px',
          width: '100%',
          margin: '50px auto 0',
          textAlign: 'left',
          overflowX: 'hidden',
        }}
      >
        <ViatorPaymentModule
          bookingId={match.params.cartId}
          showModal={showModal}
          FormContainer={WrappedPaymentForm(booking)}
          tour={tour}
          setBookError={setBookError}
          stripeRefund={stripeRefund}
        />
        <div style={{ marginTop: '50px' }}></div>
      </div>

      <Footer />
    </div>
  );
}
export default connect(
  (state) => ({
    isLoaded: state.cart.isLoaded,
    cart: state.cart.cart,
    shoppingCarthash: state.cart.shoppingCartHash,
  }),
  (dispatch) => ({
    fetchCart: (cartHash) => dispatch({ type: FETCH_CART, payload: cartHash }),
    stripeRefund: (paymentIntentId, totalRefundAmountInCents) =>
      dispatch({
        type: REFUND_STRIPE_PAYMENT,
        payload: { paymentIntentId, totalRefundAmountInCents },
      }),
    showModal: (message) => toastr.error('Payment error', message),
  })
)(ViatorPayment);
